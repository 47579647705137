<template>
  <footer class="footer">
    <div class="container">
      <div class="grid">
        <div class="logo-section">
          <img
            src="@/assets/imgs/ImgLogo/129736205_201161078280982_7725870387435406891_n.jpg"
            alt="Pousada Casa da Montanha Logo"
            width="120"
            height="110"
            class="logo"
          />
          <p class="description">Sua Estadia Perfeita em Ubajara</p>
        </div>

        <div class="contact-section">
          <h3 class="section-title">Contato</h3>
          <ul class="contact-list">
            <li class="contact-item">
              <vue-feather type="phone" size="18" class="icon"></vue-feather>
              <span>(88) 99220-2129</span>
            </li>
            <li class="contact-item">
              <vue-feather type="mail" size="18" class="icon"></vue-feather>
              <a href="mailto:contato@casadamontanha.com">contato@casadamontanha.com</a>
            </li>
          </ul>
        </div>

        <div class="location-section">
          <h3 class="section-title">Localização</h3>
          <p class="location">
            <vue-feather type="map-pin" size="18" class="icon"></vue-feather>
            <span>Av. Monsenhor Gonçalo Eufrásio - Zona rural, Ubajara - CE, 62350-000</span>
          </p>
        </div>

        <div class="social-section">
          <h3 class="section-title">Redes Sociais</h3>
          <div class="social-icons">
            <a href="https://www.facebook.com/casadamontanhaubajara/?locale=pt_BR" class="social-link">
              <vue-feather type="facebook" size="24"></vue-feather>
              <span class="sr-only">Facebook</span>
            </a>
            <a href="https://www.instagram.com/casadamontanhaubajara/" class="social-link">
              <vue-feather type="instagram" size="24"></vue-feather>
              <span class="sr-only">Instagram</span>
            </a>
          </div>
        </div>
      </div>

      <div class="nav-section">
        <nav class="nav-links">
          <a class="menu-button" @click.prevent="scrollToSection('inicio')">Início</a>
          <a class="menu-button" @click.prevent="scrollToSection('SobreNos')">Sobre Nós</a>
          <a class="menu-button" @click.prevent="scrollToSection('Quartos')">Quartos</a>
          <a class="menu-button" @click.prevent="scrollToSection('Exclusividades')">Exclusividades</a>
          <a class="menu-button" @click.prevent="scrollToSection('OndeEstamos')">Onde Estamos</a>
        </nav>
      </div>

      <div class="footer-bottom">
        <p>&copy; {{ new Date().getFullYear() }} Pousada Casa da Montanha. Todos os direitos reservados.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import VueFeather from 'vue-feather';

export default {
  name: 'FooterPage',
  components: {
    VueFeather
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        const offsets = {
          Facilities: 150,
          SobreNos: 100,
          Quartos: 100,
          OndeEstamos: 100,
          Atracoes: 60,
          Avaliacoes: 90,
          Contato: 80
        };

        const offset = offsets[sectionId] || 100;
        const topPosition = section.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({
          top: topPosition,
          behavior: 'smooth',
        });
      }
    }
  }
};
</script>

<style scoped>
.footer {
  background-color: black;
  color: white;
  padding: 1rem 0;
}

.container {
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.logo-section,
.contact-section,
.location-section,
.social-section {
  text-align: center;
  font-size: 12px;
}

.logo {
  margin-bottom: 0rem;
  border-radius: 50%;
}

.description {
  font-size: 0.875rem;
}

.section-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.location {
  align-items: center;
  gap: 0.5rem;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-link {
  color: inherit;
  transition: color 0.3s;
}

.social-link:hover {
  color: #b3b3b3;
}

.nav-section {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #4a4a4a;
}

.nav-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.menu-button {
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 4rem;
  transition: all 0.3s ease;
  border-radius: 4px;
  white-space: nowrap;
}

.menu-button:hover {
  color: #8b8b8b;
  background-color: rgba(0, 0, 0, 0.05);
}

.footer-bottom {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.875rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
</style>