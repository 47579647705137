<template>
    <section class="about-section" ref="aboutSection">
        <div class="section-container-modified">
            <div class="content-grid">
                <div class="text-content" :class="{ 'animate-in': animate }">
                    <h3>GRUTA DE UBAJARA</h3>
                    <div class="text-wrapper">
                        <p>
                            A Gruta de Ubajara, situada no Parque Nacional de Ubajara, no Ceará, é uma das mais
                            impressionantes formações geológicas do Brasil. Com cerca de 1.120 metros de extensão,
                            abriga diversas galerias adornadas por estalactites e estalagmites, esculpidas ao longo de
                            milhares de anos pela ação contínua da água.
                        </p>
                        <p>
                            Para chegar à entrada da gruta, os visitantes podem fazer uma trilha pela mata atlântica ou
                            optar pelo icônico bondinho. Além de sua fascinante beleza natural, a gruta é envolta em
                            lendas e mistérios da cultura local, oferecendo aos visitantes uma experiência única, que
                            combina história, natureza e encantamento.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="image-wrapper">
                        <img src="@/assets/imgs/attractions/ca97799c-1a39-4d2c-b608-ff2a2ee8d6a0.png"
                            alt="Gruta de Ubajara" />
                    </div>
                </div>
            </div>
        </div>

        <div class="section-container-3">
            <div class="content-grid">
                <div id="image-container" class="image-container">
                    <div class="image-wrapper">
                        <img src="@/assets/imgs/attractions/Parque_Nacional_de_Ubajara_-_Ceara_-_Brasil-scaled.jpg"
                            alt="Bondinho de Ubajara" />
                    </div>
                </div>

                <div id="text-container" class="text-content" :class="{ 'animate-in': animate }">
                    <h3>BONDINHO DE UBAJARA</h3>
                    <div class="text-wrapper-3">
                        <p>
                            O bondinho é um dos ícones do Parque Nacional de Ubajara, proporcionando uma experiência
                            inesquecível para os visitantes.
                        </p>
                        <p>
                            Com um percurso de cerca de 550 metros, ele desce pela serra oferecendo vistas panorâmicas
                            incríveis da natureza e da encosta. Durante o trajeto, é possível admirar a exuberância da
                            mata atlântica, tornando o passeio ideal para quem deseja apreciar o visual único da região.
                            Além disso, é a maneira mais rápida e prática de acessar a famosa Gruta de Ubajara.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-container-modified">
            <div class="content-grid">
                <div class="text-content" :class="{ 'animate-in': animate }">
                    <h3>FAZENDO SANTO EXPEDITO</h3>
                    <div class="text-wrapper">
                        <p>
                            A Fazenda Santo Expedito é uma das maiores produtoras de rosas híbridas do país, localizada
                            na cidade de Ubajara (Chapada da Ibiapaba - CE). Desde 2004 investe numa paixão de família:
                            a produção da mais rara e preciosa das flores.
                        </p>
                        <p>
                            De portas abertas, oferece uma experiência de turismo acessível, permitindo que você conheça
                            nossas cores e aromas por meio do Circuito das Rosas, do Espaço Aromas da Fazenda e, ainda,
                            desfrute das delícias do Restaurante da Fazenda, onde são servidas comidas típicas da
                            região.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="image-wrapper">
                        <img src="@/assets/imgs/attractions/64c570_29f4b925bc3d4b3ea002fcfc5814b97a~mv2.webp"
                            alt="Fazenda Santo Expedito" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutUs',
    data() {
        return {
            animate: false
        };
    },
    mounted() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.animate = true;
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.3 }
        );

        observer.observe(this.$refs.aboutSection);
    }
}
</script>

<style scoped>
.about-section {
    width: 100%;
    background-color: white;
    padding: 1rem 0;
}

.section-container-modified {
    width: 100%;
    max-width: 1440px;
    margin: 40px auto;
    padding: 2rem 2rem;
    box-sizing: border-box;
    background-color: #68483d;
    color: white;
    border-radius: 10px;
}

.section-container-3 {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;
}

.content-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    align-items: center;
}

.text-content {
    opacity: 0;
    transform: translateX(-30px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    text-align: left;
    max-width: 100%;
}

.title {
    font-size: 1.875rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: left;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: "Montserrat", sans-serif;
}

.text-wrapper p {
    color: white;
    line-height: 1.6;
    margin: 0;
    text-align: left;
}

.text-wrapper-3 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: "Montserrat", sans-serif;
}

.text-wrapper-3 p {
    color: #4B5563;
    line-height: 1.6;
    margin: 0;
    text-align: left;
}

.image-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.image-wrapper {
    width: 100%;
    height: 450px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.4);
}

.image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.animate-in {
    opacity: 1;
    transform: translateX(0);
}

@media (min-width: 768px) {
    .content-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 4rem;
    }
}

@media (max-width: 768px) {
    .section-container {
        padding: 0 1rem;
    }

    .image-wrapper {
        height: 300px;
    }

    .title {
        font-size: 1.5rem;
    }

    .content-grid {
        flex-direction: column;
    }

    #text-container {
        order: -1;
    }
}
</style>
