<template>
  <section class="facilities animate__animated" :class="{ 'animate__fadeInUp': hasAnimated }">
    <h2>EXCLUSIVIDADES</h2>
    <p class="description">
      A Casa da Montanha de Ubajara oferece uma experiência única com comodidades exclusivas, incluindo estacionamento
      privativo, trilhas para explorar a natureza, delicioso café da manhã, chá da tarde reconfortante, paisagens
      deslumbrantes para apreciar e conexão Wi-Fi para sua conveniência.
    </p>
    <div class="icons-grid">
      <div class="icon-item animate__animated" v-for="(icon, index) in icons" :key="index"
        :class="{ 'animate__fadeInUp': hasAnimated }"
        :style="{ animationDelay: hasAnimated ? `${index * 0.3}s` : '0s' }">
        <i :class="icon.iconClass"></i>
        <span>{{ icon.label }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import "animate.css";

export default {
  name: "HotelFacilities",
  data() {
    return {
      hasAnimated: false,
      icons: [
        { iconClass: "fas fa-parking", label: "Estacionamento" },
        { iconClass: "fas fa-hiking", label: "Trilhas" },
        { iconClass: "fas fa-apple-alt", label: "Café da manhã" },
        { iconClass: "fas fa-mug-hot", label: "Chá da Tarde" },
        { iconClass: "fas fa-mountain", label: "Paisagens Exuberantes" },
        { iconClass: "fas fa-wifi", label: "Wifi" },
      ],
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.hasAnimated) {
            this.hasAnimated = true;
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(this.$el);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.facilities {
  text-align: center;
  margin: 6rem auto;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.facilities.animate__fadeInUp {
  opacity: 1;
}

.facilities h2 {
  font-size: 2rem;
  font-weight: bold;
}

.description {
  max-width: 600px;
  margin: 1rem auto;
  color: #666;
  font-family: "Montserrat", sans-serif;
}

.icons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
}

.icon-item i {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.icon-item span {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .icons-grid {
    gap: 6rem;
  }

  .icon-item i {
    font-size: 2rem;
  }

  .icon-item span {
    font-size: 0.9rem;
  }
}
</style>
