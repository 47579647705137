<template>
    <div ref="carouselContainer" class="carousel-container fade-in">
        <div class="carousel">
            <button class="prev-button" @click="prevSlide">‹</button>

            <div class="carousel-cards">
                <div v-for="(review, index) in reviews" :key="index" class="review-card"
                    :class="{ active: index === currentIndex, previous: index === prevIndex, next: index === nextIndex }">
                    <h3>{{ review.name }}</h3>
                    <p class="review-text">“{{ review.text }}”</p>
                    <div class="stars">
                        <span v-for="star in 5" :key="star" :class="{ filled: star <= review.rating }">★</span>
                    </div>
                </div>
            </div>

            <button class="next-button" @click="nextSlide">›</button>
        </div>

        <div class="pagination">
            <span v-for="(review, index) in reviews" :key="index" :class="{ active: index === currentIndex }"
                @click="goToSlide(index)"></span>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
    name: 'ReviewCarousel',
    setup() {
        const reviews = ref([
            { name: 'Lourdes Sousa', text: 'Lugar maravilhoso, aconchegante, excelente atendimento, café da manhã e chá da tarde tudo feito com muito amor.', rating: 5 },
            { name: 'Wagner Argolo', text: 'Foi uma ótima experiência. Atendimento excelente com funcionários e proprietários muito atenciosos, corteses e disponíveis.', rating: 5 },
            { name: 'Kyara Oliveira', text: 'Lugar é excelente!!! Uma casa de fazenda!!! Funcionários atenciosos, café da manhã e lanche da tarde muito bons. Eu amei', rating: 5 },
            { name: 'Polliana Siqueira', text: 'Que experiência maravilhosa eu e minha família tivemos nesse lugar!! conseguimos aproveitar a beleza extraordinária do local.', rating: 5 },
            { name: 'Tadeu Cavalcante', text: 'Mesmo mantendo o estilo de fazendas rústica e antiga, o lugar não parou no tempo. Só melhora! E os chalés são um luxo! Com muita riqueza de detalhes.', rating: 5 },
        ]);

        const currentIndex = ref(0);
        const prevIndex = computed(() => (currentIndex.value - 1 + reviews.value.length) % reviews.value.length);
        const nextIndex = computed(() => (currentIndex.value + 1) % reviews.value.length);

        const nextSlide = () => { currentIndex.value = (currentIndex.value + 1) % reviews.value.length; };
        const prevSlide = () => { currentIndex.value = (currentIndex.value - 1 + reviews.value.length) % reviews.value.length; };
        const goToSlide = (index) => { currentIndex.value = index; };

        const carouselContainer = ref(null);

        onMounted(() => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('visible');
                            observer.unobserve(entry.target);
                        }
                    });
                },
                { threshold: 0.5 }
            );
            if (carouselContainer.value) {
                observer.observe(carouselContainer.value);
            }
        });

        return { reviews, currentIndex, prevIndex, nextIndex, nextSlide, prevSlide, goToSlide, carouselContainer };
    }
};
</script>

<style scoped>
.carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    height: 200px;
    padding: 50px;
    overflow: hidden;
    background-color: #faf9f6;
}

.carousel {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 600px;
}

.carousel-cards {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
}

.review-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    height: 180px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    opacity: 0.5;
    transform: scale(0.8);
    transition: transform 0.5s ease, opacity 0.5s ease;
    position: absolute;
}

.stars {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.stars span {
    font-size: 20px;
    color: gold;
}

.stars .filled {
    color: gold;
}

.review-card.active {
    opacity: 1;
    transform: scale(1);
    z-index: 1;
}

.review-card.previous {
    transform: translateX(-120%) scale(0.85);
    z-index: 0;
}

.review-card.next {
    transform: translateX(120%) scale(0.85);
    z-index: 0;
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 24px;
    cursor: pointer;
    z-index: 2;
}

.prev-button {
    left: 100px;
}

.next-button {
    right: 100px;
}

.pagination {
    display: flex;
    gap: 8px;
    margin-top: 40px;
}

.pagination span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
}

.pagination .active {
    background-color: #000000;
}

.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
}

.fade-in.visible {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 768px) {
    .prev-button {
        left: 10px;
    }

    .next-button {
        right: 10px;
    }

}
</style>