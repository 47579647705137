<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
            <h2>{{ title }}</h2>
            
            <div class="carousel-container">
                <div class="carousel-wrapper">
                    <transition-group name="fade">
                        <img 
                            v-for="(image, index) in images"
                            v-show="index === currentImageIndex"
                            :key="index"
                            :src="image" 
                            alt="Imagem do Quarto" 
                            class="modal-image"
                        />
                    </transition-group>
                    
                    <button 
                        class="carousel-button prev" 
                        @click="previousImage"
                        aria-label="Previous image"
                    >
                        &#10094;
                    </button>
                    <button 
                        class="carousel-button next" 
                        @click="nextImage"
                        aria-label="Next image"
                    >
                        &#10095;
                    </button>

                    <div class="carousel-dots">
                        <span 
                            v-for="(dot, index) in images.length" 
                            :key="index"
                            :class="['dot', { active: index === currentImageIndex }]"
                            @click="setImage(index)"
                        ></span>
                    </div>
                </div>
            </div>

            <div class="modal-info">
                <div class="info-grid">
                    <div class="info-item">
                        <span class="info-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M2 4v16"/>
                                <path d="M2 8h18a2 2 0 0 1 2 2v10"/>
                                <path d="M2 17h20"/>
                                <path d="M6 8v9"/>
                            </svg>
                        </span>
                        <span>{{ beds }} {{ bedType }}</span>
                    </div>
                    <div class="info-item">
                        <span class="info-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                                <circle cx="9" cy="7" r="4"/>
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"/>
                                <path d="M16 3.13a4 4 0 0 1 0 7.75"/>
                            </svg>
                        </span>
                        <span>{{ guests }} Pessoas</span>
                    </div>
                    <div class="info-item-money">
                        <span class="info-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <line x1="12" y1="1" x2="12" y2="23"></line>
                                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                            </svg>
                        </span>
                        <span>{{ price }} / Noite</span>
                    </div>
                </div>

                <div class="reservations">
                    <h3 class="reservations-title">RESERVAS:</h3>
                    <div class="social-icons">
                        <a href="https://wa.me/558892202129" class="social-icon" title="WhatsApp">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"/>
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/casadamontanhaubajara/" class="social-icon" title="Instagram">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"/>
                                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/>
                                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"/>
                            </svg>
                        </a>
                    </div>
                </div>

            </div>
            
            <button @click="closeModal" class="close-button">Fechar</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "RoomModal",
    props: {
        isVisible: Boolean,
        title: String,
        beds: Number,
        bedType: String,
        guests: Number,
        images: {
            type: Array,
            default: () => []
        },
        price: String,
    },
    data() {
        return {
            currentImageIndex: 0,
            isAnimating: false
        }
    },
    methods: {
        closeModal() {
            this.$emit("close-modal");
            this.currentImageIndex = 0;
        },
        nextImage() {
            if (this.isAnimating) return;
            this.isAnimating = true;
            this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
            setTimeout(() => {
                this.isAnimating = false;
            }, 500);
        },
        previousImage() {
            if (this.isAnimating) return;
            this.isAnimating = true;
            this.currentImageIndex = this.currentImageIndex === 0 
                ? this.images.length - 1 
                : this.currentImageIndex - 1;
            setTimeout(() => {
                this.isAnimating = false;
            }, 500);
        },
        setImage(index) {
            if (this.isAnimating) return;
            this.isAnimating = true;
            this.currentImageIndex = index;
            setTimeout(() => {
                this.isAnimating = false;
            }, 500);
        }
    },
    watch: {
        isVisible(newValue) {
            if (newValue) {
                this.currentImageIndex = 0;
            }
        }
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    text-align: center;
}

.carousel-container {
    position: relative;
    margin: 20px 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease;
    position: absolute;
    width: 100%;
    height: 100%;
}

.fade-enter-from {
    opacity: 0;
    transform: scale(1.1);
}

.fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
    transform: scale(1);
}

.carousel-wrapper {
    position: relative;
    overflow: hidden;
    height: 400px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.modal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.modal-info {
    margin: 20px 0;
}

.info-grid {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 16px 0;
    margin-left: 60px;
}

.info-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 0.95em;
}

.info-item-money {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #666;
    font-size: 0.95em;
}

.info-icon {
    display: flex;
    align-items: center;
    color: #666;
}

.close-button {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 1em;
    background-color: #777;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close-button:hover {
    background-color: #666;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.7);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s ease, background-color 0.3s ease;
}

.carousel-wrapper:hover .carousel-button {
    background: rgba(255, 255, 255, 0.9);
    opacity: 1;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.carousel-dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background-color 0.3s;
}

.dot.active {
    background: white;
}

.modal-info {
    margin: 20px 0;
}

.close-button {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 1em;
    background-color: #777;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close-button:hover {
    background-color: #666;
}

.reservations {
    margin-top: 24px;
    text-align: center;
}

.reservations-title {
    font-size: 1em;
    font-weight: 600;
    color: #666;
    margin-bottom: 12px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d4d4d4;
    color: #666;
    transition: all 0.3s ease;
}

.social-icon:hover {
    background-color: #9c9c9c;
    color: #333;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        padding: 15px;
    }

    .modal-image {
        height: 250px;
    }

    .carousel-wrapper {
        height: 250px;
    }

    .info-grid {
        flex-direction: column;
        gap: 12px;
        align-items: center;
        margin-left: 0;
    }

    .info-item, .info-item-money {
        text-align: center;
    }

    .reservations-title {
        font-size: 0.9em;
    }

    .social-icons {
        gap: 12px;
    }

    .social-icon {
        width: 32px;
        height: 32px;
    }

    .carousel-button {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }
}
</style>