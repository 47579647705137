<template>
  <div class="carousel">
    <div class="carousel-container" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
      <img v-for="(item, index) in currentImages" :key="index" :src="item.image" class="carousel-image" />
    </div>

    <div class="overlay-text">
      <h1>{{ currentImages[currentIndex].title }}</h1>
      <h2>{{ currentImages[currentIndex].subtitle }}</h2>
    </div>

    <button @click="prevImage" class="nav-button prev">‹</button>
    <button @click="nextImage" class="nav-button next">›</button>

    <div class="indicators">
      <span
        v-for="(item, index) in currentImages"
        :key="index"
        class="indicator"
        :class="{ active: index === currentIndex }"
        @click="goToImage(index)"
      ></span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";

export default {
  setup() {
    const desktopItems = [
      {
        image: require("@/assets/imgs/ImgsCarousel/image.png"),
        title: "Conforto e Elegância",
        subtitle: "Experimente a tranquilidade perfeita.",
      },
      {
        image: require("@/assets/imgs/ImgsCarousel/image3.png"),
        title: "A Beleza da Montanha",
        subtitle: "Onde a natureza encontra o luxo.",
      },
      {
        image: require("@/assets/imgs/ImgsCarousel/image2.png"),
        title: "Refúgio Perfeito",
        subtitle: "Sua casa longe de casa.",
      },
    ];

    const mobileItems = [
      {
        image: require("@/assets/imgs/ImgsCarousel/image4.png"),
        title: "Conforto e Elegância",
        subtitle: "Experimente a tranquilidade perfeita.",
      },
      {
        image: require("@/assets/imgs/ImgsCarousel/image5.png"),
        title: "Paisagens de Tirar o Fôlego",
        subtitle: "Onde a natureza encontra o luxo.",
      },
      {
        image: require("@/assets/imgs/ImgsCarousel/image6.png"),
        title: "Refúgio Perfeito",
        subtitle: "Sua casa longe de casa.",
      },
    ];

    const currentIndex = ref(0);
    const windowWidth = ref(window.innerWidth);

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    const currentImages = computed(() =>
      windowWidth.value <= 768 ? mobileItems : desktopItems
    );

    const nextImage = () => {
      currentIndex.value = (currentIndex.value + 1) % currentImages.value.length;
    };

    const prevImage = () => {
      currentIndex.value = (currentIndex.value - 1 + currentImages.value.length) % currentImages.value.length;
    };

    const goToImage = (index) => {
      currentIndex.value = index;
    };

    onMounted(() => {
      window.addEventListener("resize", updateWindowWidth);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", updateWindowWidth);
    });

    return { currentImages, currentIndex, nextImage, prevImage, goToImage };
  },
};
</script>

<style scoped>
.carousel {
  margin-top: 0px;
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.carousel-container {
  display: flex;
  height: 100%;
  transition: transform 0.5s ease;
}

.carousel-image {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: none;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  backface-visibility: hidden;
}

.overlay-text {
  position: absolute;
  bottom: 20%;
  left: 10%;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlay-text h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.overlay-text h2 {
  font-size: 2rem;
  font-weight: normal;
}

@media (max-width: 768px) {
  .overlay-text {
    bottom: 15%;
    left: 5%;
    text-align: center;
    width: 90%;
  }

  .overlay-text h1 {
    font-size: 2rem;
  }

  .overlay-text h2 {
    font-size: 1.5rem;
  }
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 2;
}

.prev {
  left: 1rem;
}

.next {
  right: 1rem;
}

.indicators {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
}

.indicator {
  width: 0.75rem;
  height: 0.75rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: white;
}

@media (min-width: 1920px) {
  .carousel {
    max-height: 90vh;
    max-width: 1920px;
    margin: 0 auto;
  }
}
</style>
