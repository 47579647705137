<template>
  <section class="about-section" ref="aboutSection">
    <div class="section-container">
      <div class="content-grid">
        <div class="text-content" :class="{ 'animate-in': animate }">
          <h2 class="title">SOBRE NÓS</h2>
          <div class="text-wrapper">
            <p>
              Bem-vindo à Casa da Montanha de Ubajara, um refúgio encantador!
              Fundada em 1988, nossa pousada traz consigo a história e o charme de uma antiga
              fazenda de café restaurada, onde tradição e conforto se encontram.
            </p>
            <p>
              Em meio à beleza da serra, você poderá desfrutar de tardes tranquilas com o nosso
              tradicional chá da tarde, cercado pela natureza e pelo encanto que só a Casa da
              Montanha pode oferecer. Venha viver momentos de paz, descanso e conexão com o que
              há de melhor em hospitalidade.
            </p>
          </div>
        </div>

        <div class="image-container">
          <div class="image-content" :class="{ 'animate-in': animate }">
            <div class="image-wrapper">
              <img src="@/assets/imgs/ImgLogo/Casa da Montanha-2-1-Photoroom.png"
                alt="Restaurant interior" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      animate: false
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.animate = true;
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(this.$refs.aboutSection);
  }
}
</script>

<style scoped>
.about-section {
  width: 100%;
  background-color: white;
}

.section-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-items: center;
}

.text-content,
.image-content {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.text-content {
  text-align: left;
  max-width: 100%;
}

.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.title {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: left;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: "Montserrat", sans-serif;
}

.text-wrapper p {
  color: #4B5563;
  line-height: 1.6;
  margin: 0;
  text-align: left;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-wrapper {
  width: 100%;
  height: 450px;
  overflow: hidden;
  border-radius: 0.5rem;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .content-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
}

@media (max-width: 768px) {
  .section-container {
    padding: 0 1rem;
  }

  .image-wrapper {
    height: 300px;
  }

  .title {
    font-size: 1.5rem;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
  }
}
</style>
