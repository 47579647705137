<template>
    <div
        class="room-card animate__animated animate__fadeInUp"
        :style="{ animationDelay: `${index * 0.3}s` }"
    >
        <img :src="image" alt="Imagem do Quarto" class="room-image" />
        <div class="room-info">
            <h3 class="room-title">{{ roomTitle }}</h3>
            <p class="room-details">
                <i class="fa fa-bed"></i> {{ beds }} <span class="span-bed">{{ bedType }}</span>
                <i class="fa fa-users"></i> {{ guests }} Pessoas
            </p>
            <button @click="openModal" class="reserve-button">DETALHES →</button>
        </div>
    </div>
</template>

<script>
import "animate.css";

export default {
    name: "RoomCard",
    props: {
        roomTitle: String,
        beds: Number,
        bedType: String,
        guests: Number,
        image: String,
        index: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            hasAnimated: false,
        };
    },
    methods: {
        openModal() {
            this.$emit("open-modal", {
                title: this.roomTitle,
                beds: this.beds,
                bedType: this.bedType,
                guests: this.guests,
                image: this.image,
            });
        }
    },
};
</script>

<style scoped>
.span-bed {
    font-size: 0.9em;
    color: #555;
    margin-right: 10px;
    font-family: "Montserrat", sans-serif;
}

.room-card {
    width: 350px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.room-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.room-info {
    padding: 16px;
}

.room-title {
    font-size: 1.2em;
    margin: 8px 0;
}

.room-details {
    font-size: 0.9em;
    color: #555;
    font-family: "Montserrat", sans-serif;
}

.reserve-button {
    margin-top: 12px;
    padding: 8px 16px;
    font-size: 0.9em;
    background-color: #ffffff;
    color: #777777;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.reserve-button:hover {
    color: #000000;
}

.room-card.prev-card .reserve-button,
.room-card.next-card .reserve-button {
  display: none;
}
</style>
