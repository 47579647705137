<template>
    <div>
        <button class="whatsapp-button" @click="toggleChat">
            <i class="fab fa-whatsapp"></i>
        </button>

        <div v-if="showChat" class="chat-box">
            <div class="chat-header">
                <div class="header-content">
                    <div class="profile-image">
                        <img src="@/assets/imgs/ImgLogo/Casa da Montanha-2-1 (1).png" alt="Profile" />
                    </div>
                    <div class="header-text">
                        <h3>Casa da Montanha Ubajara</h3>
                        <span class="online-status">Online</span>
                    </div>
                </div>
                <button class="close-button" @click="toggleChat">×</button>
            </div>
            <div class="chat-body">
                <div v-for="(msg, index) in chatMessages" :key="index" class="message-bubble">
                    <p>{{ msg.text }}</p>
                    <span class="timestamp" v-if="msg.timestamp">{{ msg.timestamp }}</span>
                </div>
            </div>
            <div class="chat-footer">
                <div class="input-container">
                    <input type="text" v-model="message" placeholder="Digite aqui..." @keyup.enter="sendMessage" />
                    <button class="send-button" @click="sendMessage">
                        <i class="fas fa-paper-plane"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
    data() {
        const now = new Date();
            const hours = now.getHours().toString().padStart(2, "0");
            const minutes = now.getMinutes().toString().padStart(2, "0");
            const timestamp = `${hours}:${minutes}`;
        return {
            showChat: false,
            message: "",
            whatsappNumber: "5588992202129",
            chatMessages: [
                {
                    text: "Olá! Entre em Contato e Tira suas Dúvidas Conosco via Whatsapp!",
                    timestamp: timestamp + " ✓✓",
                },
            ],
        };
    },
    methods: {
        toggleChat() {
            this.showChat = !this.showChat;
        },
        sendMessage() {
            if (this.message.trim() === "") {
                return;
            }

            const encodedMessage = encodeURIComponent(this.message);
            const url = `https://wa.me/${this.whatsappNumber}?text=${encodedMessage}`;
            window.open(url, "_blank");

            this.message = "";
        },
    },
};
</script>

<style scoped>
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s;
    z-index: 1000;
}

.whatsapp-button:hover {
    transform: scale(1.1);
}

.chat-box {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 330px;
    background-color: #f0f2f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    overflow: hidden;
    z-index: 999;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #008069;
    color: white;
    padding: 10px 15px;
}

.header-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #ddd;
}

.profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-text {
    display: flex;
    flex-direction: column;
}

.header-text h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.online-status {
    font-size: 12px;
    opacity: 0.8;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 0 5px;
}

.chat-body {
    padding: 20px;
    height: 300px;
    background-color: #efeae2;
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23999999' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.message-bubble {
    background: white;
    padding: 8px 12px;
    border-radius: 7.5px;
    max-width: 85%;
    position: relative;
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}

.message-bubble p {
    margin: 0;
    padding-bottom: 15px;
    font-size: 14px;
    line-height: 19px;
}

.timestamp {
    position: absolute;
    bottom: 4px;
    right: 8px;
    font-size: 11px;
    color: #667781;
}

.chat-footer {
    background-color: #f0f2f5;
    padding: 10px;
}

.input-container {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 24px;
    padding: 8px 12px;
}

.input-container input {
    flex: 1;
    border: none;
    outline: none;
    padding: 6px 10px;
    font-size: 15px;
    background: transparent;
}

.send-button {
    background: none;
    border: none;
    color: #54656f;
    padding: 8px;
    cursor: pointer;
    transition: color 0.2s;
}

.send-button:hover {
    color: #00a884;
}
</style>