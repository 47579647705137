<template>
  <nav class="navbar">
    <div class="navbar-container">
      <div class="navbar-content">
        <img 
          src="@/assets/imgs/ImgLogo/Casa da Montanha-2-1-Photoroom.png" 
          alt="Logo" 
          class="logo" 
        />
        
        <button 
          class="mobile-menu-button" 
          @click="isMenuOpen = !isMenuOpen"
          aria-label="Toggle menu"
        >
          <div class="hamburger" :class="{ 'open': isMenuOpen }">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        <div class="desktop-menu">
          <button 
            v-for="item in menuItems" 
            :key="item.name" 
            class="menu-button"
            @click="scrollToSection(item.id)"
          >
            {{ item.name }}
          </button>
        </div>
      </div>

      <div 
        class="menu-overlay"
        :class="{ 'open': isMenuOpen }"
        @click="isMenuOpen = false"
      ></div>

      <div class="mobile-menu" :class="{ 'open': isMenuOpen }">
        <button 
          v-for="item in menuItems" 
          :key="item.name" 
          class="menu-button"
          @click="() => { isMenuOpen = false; scrollToSection(item.id); }"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue'

const isMenuOpen = ref(false)

const menuItems = ref([
  { name: 'Sobre Nós', id: 'SobreNos' },
  { name: 'Quartos', id: 'Quartos' },
  { name: 'Exclusividades', id: 'Facilities' },
  { name: 'Onde Estamos', id: 'OndeEstamos' },
  { name: 'Atrações', id: 'Atracoes' },
  { name: 'Avaliações', id: 'Avaliacoes' },
  { name: 'Contato', id: 'Contato' },
])

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    const offsets = {
      Facilities: 150,
      SobreNos: 100,
      Quartos: 100,
      OndeEstamos: 100,
      Atracoes: 60,
      Avaliacoes: 90,
      Contato: 80
    };

    const offset = offsets[sectionId] || 100;
    const topPosition = section.getBoundingClientRect().top + window.scrollY - offset;

    window.scrollTo({
      top: topPosition,
      behavior: 'smooth',
    });
  }
};
</script>

<style scoped>
.navbar {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  border-radius: 10px;
  z-index: 50;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.navbar-container {
  position: relative;
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
}

.navbar-content {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 5rem;
}

.logo {
  height: 5rem;
  width: auto;
  margin-right: 0rem;
  margin-bottom: 0.5rem;
}

.desktop-menu {
  display: flex;
  gap: 1rem;
  margin-left: auto;
  align-items: center;
}

.menu-button {
  color: #4a4a4a;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  border-radius: 4px;
  white-space: nowrap;
}

.menu-button:hover {
  color: #1a1a1a;
  background-color: rgba(0, 0, 0, 0.05);
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  margin-left: auto;
}

.hamburger {
  width: 24px;
  height: 20px;
  position: relative;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #333;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.hamburger span:first-child {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 9px;
}

.hamburger span:last-child {
  top: 18px;
}

.hamburger.open span:first-child {
  transform: rotate(45deg);
  top: 9px;
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:last-child {
  transform: rotate(-45deg);
  top: 9px;
}

/* Mobile Menu Styles */
.menu-overlay {
  display: none;
}

.mobile-menu {
  display: none;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu-button {
    display: block;
  }

  .menu-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 40;
  }

  .menu-overlay.open {
    opacity: 1;
    visibility: visible;
  }

  .mobile-menu {
    display: flex;
    position: fixed;
    top: 90px;
    right: 10px;
    width: 200px;
    flex-direction: column;
    gap: 0.5rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(110%);
    transition: transform 0.3s ease;
    z-index: 60;
    padding: 1rem;
  }

  .mobile-menu.open {
    transform: translateX(0);
  }

  .mobile-menu .menu-button {
    width: 100%;
    text-align: left;
    padding: 1rem;
  }

  .mobile-menu .menu-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .desktop-menu {
    gap: 0.5rem;
  }

  .menu-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }
}

/* Large screens */
@media (min-width: 1025px) {
  .navbar-content {
    justify-content: space-between;
  }

  .desktop-menu {
    gap: 1rem;
  }
}
</style>