<template>
  <div class="home-view">
    <div class="section-container" :class="{ 'navbar-hidden': !isNavbarVisible }">
      <Navbar />
    </div>

    <div id="inicio">
      <Carousel />
    </div>

    <div class="section-container-aboutus" id="SobreNos">
      <AboutUs />
    </div>

    <div class="section-container" id="Quartos">
      <div class="room-cards-section">
        <h2 class="section-title">QUARTOS</h2>
        <div class="carousel-container">
          <button @click="prevSlide" class="carousel-button prev-button">&lt;</button>

          <div class="room-cards">
            <RoomCard v-for="(room, index) in visibleRooms" :key="index" :roomTitle="room.title" :beds="room.beds"
              :bedType="room.bedType" :guests="room.guests" :image="room.images[0]" :index="index" :price="room.price"
              @open-modal="openRoomModal(room)" class="room-card" :class="{
                active: index === 2,
                'side-active': index === 1 || index === 3,
                'prev-card': index === 0,
                'next-card': index === 4
              }" />
          </div>

          <button @click="nextSlide" class="carousel-button next-button">&gt;</button>
        </div>
      </div>
    </div>

    <RoomModal v-if="isModalVisible" :isVisible="isModalVisible" :title="selectedRoom.title" :beds="selectedRoom.beds"
      :bedType="selectedRoom.bedType" :guests="selectedRoom.guests" :images="selectedRoom.images"
      :price="selectedRoom.price" @close-modal="closeRoomModal" />

    <div class="section-container-aboutus" id="Facilities">
      <HotelFacilities />
    </div>

    <div class="parallax-section" :class="{ 'ios-fixed': isIOS }">
    </div>

    <div class="section-container-map" id="OndeEstamos">
      <section class="map-section">
        <h2 class="section-title-map">ONDE ESTAMOS</h2>
        <HotelMap />
      </section>
    </div>

    <div class="section-container-attractions" id="Atracoes">
      <section class="attraction-section">
        <h2 class="section-title-attraction">PONTOS TURÍSTICOS</h2>
        <TouristAttractions />
      </section>
    </div>

    <div class="section-container" id="Avaliacoes">
      <section class="avaliation-section">
        <h2 class="section-title-avaliation">AVALIAÇÕES</h2>
        <AvaliationSection />
      </section>
    </div>

    <div class="section-container-footer" id="Contato">
      <FooterSection />
    </div>

    <WhatsAppButton />
  </div>
</template>

<script>
import Navbar from '../components/navbar/NavbarHome.vue';
import Carousel from '../components/Carousel/CarouselHome.vue';
import AboutUs from '../components/AboutUs/AboutUs.vue';
import RoomCard from '../components/RoomCard/RoomCard.vue';
import RoomModal from '../components/RoomModal/RoomModal.vue';
import HotelMap from '../components/HotelMap/HotelMap.vue';
import HotelFacilities from '../components/HotelFacilities/HotelFacilities.vue';
import FooterSection from '../components/FooterPage/FooterPage.vue';
import TouristAttractions from '../components/TouristAttractions/TouristAttractions.vue';
import AvaliationSection from '../components/AvaliationSection/AvaliationSection.vue';
import WhatsAppButton from '../components/WhatsappButton/WhatsappButton.vue';
import { ref, onMounted, onUnmounted } from 'vue'

import CasalMasterImage1 from '@/assets/imgs/ImgsRooms/suites/Casal/163ee5bd-036b-4849-b6b2-2a86d05c7803.jpg';
import CasalImage2 from '@/assets/imgs/ImgsRooms/suites/Casal/288abb99-b917-4fc2-8ea7-bc7c6086c6e5.jpg';
import CasalImage3 from '@/assets/imgs/ImgsRooms/suites/Casal/d1d3ff3d-0ae2-4c00-a8f9-6308d36b7ba4.jpg';
import CasalMasterImage2 from '@/assets/imgs/ImgsRooms/suites/CasalMaster/image.png';
import Suico1 from '@/assets/imgs/ImgsRooms/chales/suico/042e9e25-aad7-43d4-ba8e-2a9386011ad8.jpg';
import Suico2 from '@/assets/imgs/ImgsRooms/chales/suico/0717f34f-a197-4eeb-970b-a2c1acf95b1c.jpg';
import Suico3 from '@/assets/imgs/ImgsRooms/chales/suico/ae9f0307-3943-496b-822d-4328e1c7c408.jpg';
import Rosa1 from '@/assets/imgs/ImgsRooms/chales/rosa/c02ef452-7d4d-41fe-b958-7991a4cd3595.jpg';
import Rosa2 from '@/assets/imgs/ImgsRooms/chales/rosa/0c13c41c-c08f-4973-b579-1b21fc28a46c.jpg';
import Colonial1 from '@/assets/imgs/ImgsRooms/chales/colonial/7fe97d4b-baae-4eed-9858-33e1c3162a0b.jpg';
import Colonial2 from '@/assets/imgs/ImgsRooms/chales/colonial/88e3001e-fc86-4b81-96d3-ce18d8aabf9c.jpg';
import Colonial3 from '@/assets/imgs/ImgsRooms/chales/colonial/de619582-b524-4038-8592-f18c3f43814c.jpg';
import Colonial4 from '@/assets/imgs/ImgsRooms/chales/colonial/e1733673-c999-4bf1-863e-a446be5d939c.jpg';
import Janelas1 from '@/assets/imgs/ImgsRooms/chales/7janelas/350fdfdc-be28-4a57-a4c5-385fe28f0bbd.jpg';
import Janelas2 from '@/assets/imgs/ImgsRooms/chales/7janelas/577f3f33-39eb-4196-b9e6-926bdf5f1e7d.jpg';
import Janelas3 from '@/assets/imgs/ImgsRooms/chales/7janelas/b2e78cb9-76b7-434c-80ee-f475de13b911.jpg';
import Janelas4 from '@/assets/imgs/ImgsRooms/chales/7janelas/image.png';


export default {
  components: {
    Navbar,
    Carousel,
    AboutUs,
    RoomCard,
    RoomModal,
    HotelMap,
    HotelFacilities,
    FooterSection,
    TouristAttractions,
    AvaliationSection,
    WhatsAppButton,
  },
  data() {
    return {
      isIOS: false,
      isModalVisible: false,
      selectedRoom: {},
      currentSlide: 0,
      roomsPerView: 3,
      rooms: [
        {
          title: 'Suíte Casal',
          beds: 1,
          bedType: 'Casal',
          guests: "2 - 3",
          images: [CasalImage2, CasalImage3],
          price: 'R$940,00'
        },
        {
          title: 'Chalé Colonial',
          beds: 2,
          bedType: 'Casal',
          guests: "3 - 4",
          images: [Colonial1, Colonial2, Colonial3, Colonial4],
          price: 'R$1.600,00'
        },
        {
          title: 'Chalé 7 Janelas',
          beds: 3,
          bedType: 'Casal',
          guests: "3 - 4",
          images: [Janelas1, Janelas2, Janelas3, Janelas4],
          price: 'R$1.990,00'
        },
        {
          title: 'Chalé Rosa',
          beds: 2,
          bedType: 'Casal',
          guests: "3 - 4",
          images: [Rosa1, Rosa2],
          price: 'R$1.680,00'
        },
        {
          title: 'Chalé Suiço',
          beds: 1,
          bedType: 'Casal',
          guests: "2 - 3",
          images: [Suico1, Suico2, Suico3],
          price: 'R$1.250,00'
        },
        {
          title: 'Suíte Casal Master',
          beds: 1,
          bedType: 'Casal',
          guests: "2 - 3",
          images: [CasalMasterImage1, CasalMasterImage2],
          price: 'R$1.035,00'
        },
      ],
    };
  },
  computed: {
    visibleRooms() {
      const rooms = [];
      for (let i = 0; i < 5; i++) {
        const index = (this.currentSlide + i - 1 + this.rooms.length) % this.rooms.length;
        rooms.push(this.rooms[index]);
      }
      return rooms;
    }
  },
  methods: {
    detectIOS() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      this.isIOS = /iphone|ipad|ipod/.test(userAgent);
    },
    openRoomModal(room) {
      this.selectedRoom = { ...room };
      this.isModalVisible = true;
    },
    closeRoomModal() {
      this.isModalVisible = false;
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.rooms.length;
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + this.rooms.length) % this.rooms.length;
    },
    observeRoomCards() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !entry.target.classList.contains("animated")) {
              entry.target.classList.add("in-view", "animated");
            }
          });
        },
        { threshold: 0.1 }
      );

      this.$nextTick(() => {
        const cards = document.querySelectorAll(".room-card.prev-card, .room-card.next-card");
        cards.forEach((card) => observer.observe(card));
      });
    },
  },
  mounted() {
    this.detectIOS();
    this.observeRoomCards();
  },
  setup() {
    const isNavbarVisible = ref(true)
    const lastScrollY = ref(0)

    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const documentHeight = document.documentElement.scrollHeight
      const windowHeight = window.innerHeight
      const scrolledToBottom = currentScrollY + windowHeight >= documentHeight - 100

      if (scrolledToBottom) {
        isNavbarVisible.value = false
      } else {
        isNavbarVisible.value = true
      }

      lastScrollY.value = currentScrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll, { passive: true })
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      isNavbarVisible,
    }
  },
};
</script>

<style scoped>
.parallax-section {
  position: relative;
  background-image: url('@/assets/imgs/230215_PARQUE-NACIONAL-DE-UBAJARA_TF_1079-scaled.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.ios-fixed {
  background-attachment: scroll;
}

.parallax-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.parallax-content h2 {
  font-size: 2.5em;
  margin: 0;
}

.parallax-content p {
  font-size: 1.2em;
  margin-top: 10px;
}

.navbar-hidden {
  transition: transform 0.3s ease-in-out;
  transform: translateY(-100%);
}

.home-view {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.section-container {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: #faf9f6;
}

.section-container-aboutus {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: white;
}

.section-container-map {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: #faf9f6;
}

.section-container-attractions {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: white;
}

.section-container-footer {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  box-sizing: border-box;
}

.map-section,
.attraction-section {
  width: 100%;
  text-align: center;
  padding: 4rem 0;
}

.avaliation-section {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  background-color: #faf9f6;
}

.section-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 30px;
}

.section-title-map {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 40px;
}

.section-title-attraction {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: -20px;
}

.section-title-avaliation {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 4px;
}

.room-cards-section {
  width: 100%;
  text-align: center;
  padding: 4rem 0;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 2rem;
  min-height: 300px;
  width: 100%;
  box-sizing: border-box;
}

.room-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  perspective: 2000px;
}

.room-card {
  flex: 0 0 auto;
  width: 300px;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  transform-style: preserve-3d;
}

.room-card.active {
  transform: translateZ(50px) scale(1.05);
  z-index: 3;
  filter: brightness(100%) contrast(105%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.room-card.side-active {
  width: 300px;
  height: 380px;
  filter: brightness(100%);
  z-index: 1;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

:deep(.room-card.side-active) .room-info {
  padding: 0px;
}

.room-card.in-view {
  animation: fadeIn 0.6s ease-in-out forwards;
}

.carousel-button {
  background-color: #333;
  color: white;
  border: none;
  font-size: 24px;
  padding: 12px 16px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-button:hover {
  background-color: #555;
  transform: translateY(-50%) scale(1.1);
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 0.6;
    transform: translateX(0px) translateZ(-100px) scale(0.8);
    z-index: 1;
    filter: brightness(50%) blur(1px);
    animation: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (max-width: 768px) {
  .section-container {
    padding: 0 0.5rem;
  }

  .section-container-footer {
    padding: 0;
  }

  .carousel-container {
    padding: 0 1rem;
  }

  .carousel-button {
    padding: 8px 16px;
  }

  .section-container-aboutus {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 0 1rem;
    box-sizing: border-box;
    background-color: white;
  }
}
</style>