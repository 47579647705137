<template>
  <div class="section-container-map">
    <div ref="containerRef" class="map-and-info-container">
      <div id="map-container" v-if="isVisible" class="map-container animate__animated animate__fadeInLeft">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15923.61269198103!2d-40.9133401!3d-3.8309478!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x794cafdd32b85c7%3A0xf29b6863ec788516!2sCasa%20da%20Montanha%20Ubajara!5e0!3m2!1spt-BR!2sbr!4v1730428283498!5m2!1spt-BR!2sbr"
          style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div id="info-container" v-if="isVisible" class="info-container animate__animated animate__fadeInRight">
        <p>
          A Casa da Montanha Ubajara está localizada em um ponto estratégico da Serra da Ibiapaba, uma região de beleza
          natural exuberante, clima agradável e rica em atrativos turísticos, como trilhas, cachoeiras e o famoso Parque
          Nacional de Ubajara.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";

export default {
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.isVisible = true;
          observer.unobserve(this.$refs.containerRef);
        }
      },
      { threshold: 0.1 }
    );
    observer.observe(this.$refs.containerRef);
  },
};
</script>

<style scoped>
.section-container-map {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-sizing: border-box;
  background-color: white;
}

.map-and-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 100%;
}

.map-container {
  width: 100%;
  max-width: 500px;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.map-container iframe {
  width: 100%;
  height: 100%;
}

.info-container {
  max-width: 400px;
  padding: 1rem;
}

.info-container p {
  margin: 0;
  line-height: 1.8;
  font-size: 1.1rem;
  color: #4a4a4a;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

@media (max-width: 1024px) {
  .map-and-info-container {
    gap: 2rem;
  }

  .map-container {
    max-width: 450px;
    height: 350px;
  }
}

@media (max-width: 768px) {
  .section-container {
    padding: 1.5rem 1rem;
  }

  .map-and-info-container {
    flex-direction: column;
  }

  .map-container {
    max-width: 100%;
    height: 300px;
  }

  .info-container {
    max-width: 100%;
  }

  .info-container p {
    font-size: 1rem;
    line-height: 1.6;
  }

  .section-title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  #info-container {
    order: -1;
  }

  #map-container {
    order: 0;
  }
}
</style>